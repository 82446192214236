<template>
  <div class="user-info">
    <div class="order-wrap">
      <div class="order-title">我的资料</div>
      <div class="order-main">
        <div class="edge-title">基本信息</div>
        <div class="edge-content">
          <a-row>
            <a-col :span="8">
              <div class="info-label">公司名称:</div>
              <div class="info-context">{{ data.company }}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-label">所在城市:</div>
              <div class="info-context">{{ data.province }} {{ data.city }}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-label">营业执照:</div>
              <div class="info-context">{{ data.license }}</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <div class="info-label">航协号:</div>
              <div class="info-context">{{ data.iata }}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-label">OFFICE号:</div>
              <div class="info-context">{{ data.office }}</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <div class="info-label">银行开户名:</div>
              <div class="info-context">{{ data.accountName }}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-label">开会银行:</div>
              <div class="info-context">{{ data.bank }}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-label">银行账号:</div>
              <div class="info-context">{{ data.accountNumber }}</div>
            </a-col>
          </a-row>
        </div>
        <div class="edge-title">联系信息</div>
        <div class="edge-content">
          <a-row>
            <a-col :span="8">
              <div class="info-label">业务联系人:</div>
              <div class="info-context">{{ data.businessContact }}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-label">业务联系电话:</div>
              <div class="info-context">{{ data.businessMobile }}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-label">联系邮箱:</div>
              <div class="info-context">{{ data.email }}</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <div class="info-label">财务联系人:</div>
              <div class="info-context">{{ data.financeContact }}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-label">财务联系电话:</div>
              <div class="info-context">{{ data.financeMobile }}</div>
            </a-col>
            <a-col :span="8">
              <div class="info-label">所属销售大区:</div>
              <div class="info-context">{{ data.deptName }}</div>
            </a-col>
          </a-row>
          <a-row>
            <a-col :span="8">
              <div class="info-label">航班不正常联系手机:</div>
              <div class="info-context">{{ data.fltExpMobile }}</div>
            </a-col>
            <a-col :span="16">
              <div class="info-label">公司地址:</div>
              <div class="info-context">{{ data.address }}</div>
            </a-col>
          </a-row>
        </div>
        <div class="edge-title">其他信息</div>
        <div class="edge-content">
          <a-row>
            <a-col :span="8">
              <div class="info-label">注册日期:</div>
              <div class="info-context">{{ data.createTime | format }}</div>
            </a-col>
            <a-col :span="16">
              <div class="info-label">网站账号:</div>
              <div class="info-context">{{ data.username }}</div>
            </a-col>
          </a-row>
        </div>
        <div class="user-action">
            <a-button type="primary" @click="onShowEdit">修改</a-button>
        </div>
      </div>
    </div>
    <a-modal
      :visible="visible"
      :confirm-loading="confirmLoading"
      @ok="handleOk"
      @cancel="()=>{visible=false}"
    >
    <a-form :form="updateForm" :label-col="{ span: 8 }" :wrapper-col="{ span: 14 }">
      <a-form-item label="业务联系人">
        <a-input
        size="small"
        v-decorator="['updateAgentUserInfo.businessContact',
          {
            rules: [{ validator: handleFieldValidate }],
            initialValue: data.businessContact,
            trigger: 'blur',
          }]">
        </a-input>
      </a-form-item>
      <a-form-item label="业务联系电话">
        <a-input
        size="small"
        v-decorator="['updateAgentUserInfo.businessMobile',
          {
            rules: [{ validator: handleFieldValidate }],
            initialValue: data.businessMobile,
            trigger: 'blur',
          }]">
        </a-input>
      </a-form-item>
      <a-form-item label="联系邮箱">
        <a-input
        size="small"
        v-decorator="['updateAgentUserInfo.email',
          {
            rules: [{ validator: handleFieldValidate }],
            initialValue: data.email,
            trigger: 'blur',
          }]">
        </a-input>
      </a-form-item>
      <a-form-item label="财务联系人">
        <a-input
        size="small"
        v-decorator="['updateAgentUserInfo.financeContact',
          {
            rules: [{ validator: handleFieldValidate }],
            initialValue: data.financeContact,
            trigger: 'blur',
          }]">
        </a-input>
      </a-form-item>
      <a-form-item label="财务联系电话">
        <a-input
        size="small"
        v-decorator="['updateAgentUserInfo.financeMobile',
          {
            rules: [{ validator: handleFieldValidate }],
            initialValue: data.financeMobile,
            trigger: 'blur',
          }]">
        </a-input>
      </a-form-item>
      <a-form-item label="航班不正常联系手机">
        <a-input
        size="small"
        v-decorator="['updateAgentUserInfo.fltExpMobile',
          {
            rules: [{ validator: handleFieldValidate }],
            initialValue: data.fltExpMobile,
            trigger: 'blur',
          }]">
        </a-input>
      </a-form-item>
      <a-form-item label="公司地址">
        <a-input
        size="small"
        v-decorator="['updateAgentUserInfo.address',
          {
            rules: [{ validator: handleFieldValidate }],
            initialValue: data.address,
            trigger: 'blur',
          }]">
        </a-input>
      </a-form-item>
    </a-form>
    </a-modal>
  </div>
</template>

<script>
import { userDetail, userUpdate } from '@/api/user'
import { format } from '@/filters/time'

import { validate } from '@/utils/validate'

export default {
  data () {
    return {
      updateForm: this.$form.createForm(this, { name: 'edit-agent-user' }),
      data: {},
      visible: false,
      confirmLoading: false
    }
  },
  created () {
    this.fetch()
  },
  methods: {
    async fetch () {
      try {
        this.data = await userDetail()
      } catch (err) {
        this.$message.error(err.message)
      }
    },
    handleFieldValidate (rule, value, callback) {
      validate(
        'update-agent-user',
        this.updateForm.getFieldsValue(),
        rule.fullField
      ).then(({ valid, error }) => {
        if (valid) {
          callback()
        } else {
          callback(error)
        }
      })
    },
    async submitUpdate () {
      const formFields = this.updateForm.getFieldsValue()

      this.params = {
        ...this.data,
        ...formFields.updateAgentUserInfo
      }

      // console.log(this.params)
      this.confirmLoading = true
      try {
        await userUpdate(this.params)
        this.confirmLoading = false
        this.$success({
          content: '用户信息修改成功',
          centered: true,
          onOk: async () => {
            this.visible = false
          }
        })
        this.fetch()
      } catch (e) {
        this.$message.error(e.message)
        this.confirmLoading = false
      }
    },
    onShowEdit () {
      this.visible = true
    },
    handleOk () {
      this.updateForm.validateFields((err, values) => {
        if (!err) {
          this.submitUpdate()
        }
      })
    }
  },
  filters: {
    format
  }
}
</script>

<style lang="less" scoped>
.edge-title {
  font-size: 14px;
  font-weight: bold;
  padding-left: 16px;
  border-left: 2px solid @joy-orange-color;
}
.edge-content {
  margin-top: 16px;
  padding: 0 16px;

  + .edge-title {
    margin-top: 20px;
  }
}

.info-label {
  display: table-cell;
  padding-right: 16px;
  line-height: 34px;
}
.info-context {
  display: table-cell;
}

.user-action {
    text-align: center;
    padding-top: 20px;
}
</style>
